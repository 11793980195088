import Swiper from "swiper/bundle";

const ExhibitionsBox = () => {
	const block = document.querySelectorAll(".exhibitions-box");
	if (!block) {
		return;
	}
	const breakpoint = window.matchMedia("(min-width: 768px)");

	block.forEach((item) => {
		const sliderWrap = item.querySelector(".exhibitions-box__list-wrap");
		const slider = item.querySelector(".exhibitions-box__list");
		const sliderItems = item.querySelectorAll(".exhibitions-box__list-item");
		let mySwiper;

		const handleInitSLider = () => {
			slider.classList.add("swiper-wrapper");

			sliderItems.forEach((slide) => {
				slide.classList.add("swiper-slide");
			});

			mySwiper = new Swiper(sliderWrap, {
				slidesPerView: 1.19,
				initialSlide: 0
			});
		};

		const breakpointChecker = function() {
			if (breakpoint.matches === true) {
				if (mySwiper !== undefined) {
					mySwiper.destroy(true, true);
					sliderItems.forEach((slide) => {
						slide.classList.remove("swiper-slide");
					});
				}
				return;
			} else if (breakpoint.matches === false) {
				handleInitSLider();
			}
		};

		breakpoint.addListener(breakpointChecker);
		breakpointChecker();
	});
};


export default ExhibitionsBox;