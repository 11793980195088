import Plyr from "plyr";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Scrollbar from "smooth-scrollbar";
import initialize from "easy-toggle-state";

const HalfPageLayout = () => {
	const block = document.querySelectorAll(".half-page-layout");
	if (!block) {
		return;
	}

	block.forEach(item => {
		const back = item.querySelector(".half-page-layout__back");
		const tabsButtons = item.querySelectorAll(".tabs-nav [data-toggle-radio-group]")
		const gallery = item.querySelectorAll(".half-page-layout__gallery");
		const galleryVideo = item.querySelectorAll(".half-page-layout__gallery-video");
		const scrollContainer = item.querySelector(".scrolling-container");
		const iframeForms = item.querySelectorAll(".subscribe-box iframe")
		const isMobile = window.innerWidth < 768;
		let players = null;

		if (isMobile || iframeForms?.length) {
			Scrollbar.destroy(scrollContainer);
		}

		const hideGallery = () => {
			pauseAllVideos();

			gallery.forEach(el => {
				el.classList.remove("active");
			});
		};

		back.addEventListener("click", (e) => {
			if (window.history.length > 2) {
				history.back();
			} else {
				window.location.href = '/';
			}
		});

		galleryVideo.forEach(g => {
			const videoWrap = document.createElement("div");
			videoWrap.classList.add("half-page-layout__gallery-video-wrap");
			videoWrap.setAttribute("data-video", g.getAttribute("data-video"));
			g.append(videoWrap);

			const videoFrame = document.createElement("iframe");
			videoFrame.setAttribute("src", g.getAttribute("data-video"));
			g.querySelector(".half-page-layout__gallery-video-wrap").append(videoFrame);
		});

		if (item.querySelector(".half-page-layout__gallery-video-wrap")) {
			players = Plyr.setup(document.querySelectorAll(".half-page-layout__gallery-video-wrap"), {
				controls: ["play-large", "play", "progress", "current-time"],
				settings: [],
				youtube: {
					controls: 0,
					showinfo: 0,
					disablekb: 1,
					enablejsapi: 1
				}
			});

			// set poster
			galleryVideo.forEach(el => {
				const posterImage = el.getAttribute("data-image");

				if (posterImage) {
					el.querySelector(".plyr__poster").style.backgroundImage = `url("${posterImage}")`;
					el.querySelector(".plyr__poster").style.opacity = 1;
				}
			});
		}

		const pauseAllVideos = () => {
			if (players) {
				players.forEach(player => {
					player.pause();
				});
			}
		};

		if (players) {
			players.forEach(player => {
				player.on("play", () => {
					const others = players.filter(other => other != player);

					others.forEach(other => {
						other.pause();
					});
				});
			});
		}

		tabsButtons.forEach(button => {
			let initMobileWidget = false;

			button.addEventListener("click", (e) => {
				const targetGallery = e.target.getAttribute("data-gallery-id");
				const widget = item.querySelector(`${targetGallery} .half-page-layout__widget`);
				const showMobileWidget = widget && window.innerWidth < 768;

				if (showMobileWidget && !initMobileWidget) {
					item.querySelector(`${targetGallery}`).classList.add("half-page-layout__gallery_mobile-widget");
					const widgetContent = widget.querySelector(".half-page-layout__widget-inner");
					const targetTab = item.querySelector(e.target.getAttribute("data-toggle-target"));
					targetTab.prepend(widgetContent.cloneNode(true));
					targetTab.querySelector(".half-page-layout__widget-button")?.removeAttribute("style");

					initialize();
					initMobileWidget = true;
				}

				if (targetGallery && !showMobileWidget) {
					hideGallery();
					item.querySelector(`${targetGallery}`).classList.add("active");
				} else {
					if (!gallery[0].classList.contains("active")) {
						hideGallery();
						gallery[0].classList.add("active");
					}
				}
			});
		});

		// mix blend mode drop menu
		if (item.classList.contains("half-page-layout_mix-blend-mode")) {
			const styles = item.getAttribute("style");

			document.querySelectorAll(".drop-menu").forEach(menu => {
				menu.classList.add("drop-menu_mix-blend-mode");
				menu.setAttribute("style", styles);
			});

			const css = `.drop-menu .fui-form .fui-input:-webkit-autofill { box-shadow: 0 0 0 60rem ${item.style.backgroundColor} inset !important; }`;
			const style = document.createElement("style");
			style.append(document.createTextNode(css));

			item.append(style);
		}


		// collection
		const collection = item.querySelector(".half-page-layout__collection");

		if (collection) {
			gsap.registerPlugin(ScrollTrigger);
			const collectionItems = collection.querySelectorAll(".half-page-layout__gallery-image");
			const collectionInner = collection.querySelector(".half-page-layout__collection-inner");

			const bar = Scrollbar.init(
				collection.querySelector(".half-page-layout__collection-inner", {
					alwaysShowTracks: false,
					damping: window.innerWidth < 1024 ? 0.2 : 0.1
				})
			);

			ScrollTrigger.scrollerProxy(collectionInner, {
				scrollTop(value) {
					if (arguments.length) {
						bar.scrollTop = value;
					}
					return bar.scrollTop;
				}
			});
			bar.addListener(ScrollTrigger.update);

			collectionItems.forEach((el, index) => {
				const TL = gsap.timeline({
					scrollTrigger: {
						trigger: el,
						scroller: collectionInner,
						start: `${index === 0 ? "top top" : "top bottom"}`,
						end: `${index === 0 ? "+=100%" : "+=280%"}`,
						scrub: true,
						invalidateOnRefresh: true,
					}
				})
				if (index === 0) {
					TL.fromTo(el, { scale: 1 }, { scale: 0.5, ease: 'none' });
				} else {
					TL.fromTo(el, { scale: 1.9 }, { scale: 0.5, ease: 'none' });
				}
			});
		}
	});
};


export default HalfPageLayout;