import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Scrollbar from "smooth-scrollbar";

const HomePage = () => {
	// const isMobile = window.innerWidth < 1024;
	const exhibitWrap = document.querySelector("#exhibit-info");
	const scrollingContainer = document.querySelector(".scrolling-container");
	gsap.registerPlugin(ScrollTrigger);

	const bar = Scrollbar.init(scrollingContainer, {
		alwaysShowTracks: false,
		// damping: isMobile ? 0.2 : 0.1
		damping: 0.1
	});

	ScrollTrigger.scrollerProxy(scrollingContainer, {
		scrollTop(value) {
			if (arguments.length) {
				bar.scrollTop = value;
			}
			return bar.scrollTop;
		}
	});
	bar.addListener(ScrollTrigger.update);


	// main slides
	const sections = document.querySelectorAll(".slide");
	sections.forEach((el, position) => {
		const isFirst = position === 0;
		const isLast = position === sections.length - 1;
		const exhibitiInfo = el.querySelector(".exhibit-info");
		const imageWrapper = el.querySelector(".slide-inner-image-wrapper");
		const slideBadge = el.querySelector(".slide-badge");
		const wrapper = el.querySelector(".slide-wrapper");

		if (isFirst) {
			exhibitWrap.innerHTML = exhibitiInfo.innerHTML;
		}
		gsap.timeline({
			scrollTrigger: {
				trigger: el,
				scroller: ".scrolling-container",
				start: "top bottom",
				end: "+=150%",
				scrub: true,
				// invalidateOnRefresh: true,
				onEnter: () => {
					exhibitWrap.innerHTML = exhibitiInfo.innerHTML;
				},
				onEnterBack: () => {
					exhibitWrap.innerHTML = exhibitiInfo.innerHTML;
				},
				onLeave: () => {
					if (isLast) {
						exhibitWrap.innerHTML = "";
					}
				}
			}
		})

		if (!isFirst) {
			// scale out
			gsap.timeline({
				scrollTrigger: {
					trigger: el,
					scroller: ".scrolling-container",
					start: "top -50%",
					end: "+=100%",
					scrub: true
				}
			})
			.fromTo(el, { scaleY: 1 }, { scaleY: 0, ease: "none" });

			// scale in
			const showTL = gsap.timeline({
				scrollTrigger: {
					trigger: el,
					scroller: ".scrolling-container",
					start: "top bottom",
					end: "+=100%",
					scrub: true
				}
			});
			showTL.fromTo(el, { scaleY: 0 }, { scaleY: 1, ease: "none" }, 0)
			showTL.set(el, { transformOrigin: "bottom center" });
		}

		const contentTL = gsap.timeline({
			scrollTrigger: {
				trigger: el,
				scroller: ".scrolling-container",
				start: "top top",
				end: "+=50%",
				pin: true,
				scrub: 0.2
			}
		})
		if (imageWrapper) {
			contentTL.fromTo(imageWrapper, { scaleY: 0 }, { scaleY: 1, ease: "none" });
		}
		if (slideBadge) {
			contentTL.fromTo(slideBadge, { scaleY: 0 }, { scaleY: 1, ease: "none" });
		}
		if (wrapper) {
			// contentTL.set(wrapper, { opacity: 1 });
			contentTL.fromTo(wrapper, { scaleY: 0 }, { scaleY: 1, ease: "none" });
			// contentTL.set(wrapper, { transformOrigin: "top center" });
			// contentTL.to(wrapper, { scaleY: 0, ease: "none" });
			// contentTL.set(wrapper, { opacity: 0 });
		}

		if (isFirst) {
			const firstTL = gsap.timeline({
				scrollTrigger: {
					trigger: el,
					scroller: ".scrolling-container",
					start: "top top",
					end: "+=100%",
					scrub: true,
					onEnter: () => {
						ScrollTrigger.refresh();
					}
				}
			})
			firstTL.fromTo(el, { scaleY: 1 }, { scaleY: 0, ease: "none" }, 0);
		}
	});


	// restore page scroll position on history back
	document.querySelectorAll("a").forEach(link => {
		link.addEventListener("click", function(e) {
			sessionStorage.setItem("scrollPosition_" + e.target.getAttribute("href"), 0);
		});
	});
	window.addEventListener("beforeunload", () => {
		sessionStorage.setItem("scrollPosition_" + window.location.href, bar.offset.y);
	})
	bar.setPosition(0, sessionStorage.getItem("scrollPosition_" + window.location.href));


	//regular titles not involved in squish effect
	function fitSvgTextElements() {
		const elements = document.querySelectorAll("svg.text");
		for(const el of elements) {
			const box = el.querySelector("text").getBBox();
			el.setAttribute("viewBox", `${box.x} ${box.y} ${box.width} ${box.height}`);
			el.dispatchEvent(new Event("fit-svg", {"bubbles": true}));
		}
	}
	window.addEventListener("load", fitSvgTextElements);
	setTimeout(function() {
		//run resize event in case it runs too early. fade in text after resize.
		const elements = document.querySelectorAll("svg.text");
		for(const el of elements) {
			el.classList.add("visible");
		}
		window.dispatchEvent(new Event("resize"));
	}, 100)
	window.addEventListener("resize", fitSvgTextElements);


	//loading div
	const isLoaded = sessionStorage.getItem("alps-loaded");
	const logoLoading = document.querySelector("#alps-logo-loading");
	const loadingDiv = document.querySelector(".loading-screen");
	if (loadingDiv) {
		if (isLoaded) {
			loadingDiv.remove();
		} else {
			logoLoading.classList.remove("start");
			loadingDiv.classList.remove("start");

			sessionStorage.setItem("alps-loaded", "true");
		}
	}
};

export default HomePage;