const ContactBox = () => {
	const block = document.querySelectorAll(".contact-box");
	if (!block) {
		return;
	}

	block.forEach((item, index) => {
		if ((index + 1) === block.length) {
			item.classList.add("contact-box_last");
		}
	});
};


export default ContactBox;