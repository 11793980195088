import Scrollbar from "smooth-scrollbar";
import { isActive } from "easy-toggle-state";

const EventLayout = () => {
	const block = document.querySelectorAll(".event-layout");
	if (!block) {
		return;
	}

	block.forEach(item => {
		const isSimpleState = item.classList.contains("event-layout_simple");
		const mobileListToggle = item.querySelector(".event-layout__list-toggle");

		if (!isSimpleState) {
			const initEventLayout = () => {
				const listContainer = item.querySelector(".event-layout__list");
				const isMobile = window.innerWidth < 768;
				let scrollbar;
				let bar;

				if (!bar && !isMobile) {
					scrollbar = Scrollbar;
					bar = Scrollbar.init(listContainer, {
						alwaysShowTracks: false,
						damping: isMobile ? 0.2 : 0.1
					});
				} else {
					if (isMobile && bar) {
						Scrollbar.destroy(listContainer);
					}
				}
			};
			initEventLayout();

			window.addEventListener("resize", () => {
				initEventLayout();
			});
		}

		mobileListToggle.addEventListener("toggleAfter", event => {
			if (isActive(event.target)) {
				window.dispatchEvent(new Event("resize-scaled-text"));
			}
		});
	});
};


export default EventLayout;