const TilesList = () => {
	const block = document.querySelectorAll(".tiles-list");
	if (!block) {
		return;
	}

	const setListItemHeight = () => {
		block.forEach(el => {
			el.querySelectorAll(".tiles-list__item").forEach(item => {
				item.style.minHeight = `${item.offsetWidth}px`;
			});
		});
	};

	setListItemHeight();
	window.addEventListener("resize", setListItemHeight);
};


export default TilesList;