import Plyr from "plyr";

const AudioWidget = () => {
	const block = document.querySelector(".audio-widget");
	if (!block) {
		return;
	}

	const players = Plyr.setup(document.querySelectorAll(".audio-widget__track"), {
		controls: ["play", "progress", "current-time"],
		settings: []
	});

	players.forEach(player => {
		player.on("play", () => {
			const others = players.filter(other => other != player);

			others.forEach(other => {
				other.pause();
			});
		});
	});
};


export default AudioWidget;