import { docReady } from "./utils";
import easyToggle from "easy-toggle-state";
import initialize from "easy-toggle-state";
import { isActive } from "easy-toggle-state";
import { gsap } from "gsap";
import { ScrollTrigger, Draggable } from "gsap/all";
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import vhCheck from 'vh-check';
// import ScrollAnimation from "./blocks/ScrollAnimation";
import ExhibitionsBox from "./blocks/ExhibitionsBox";
import ContactBox from "./blocks/ContactBox";
import InfoTiles from "./blocks/InfoTiles";
import InfoList from "./blocks/InfoList";
import HalfPageLayout from "./blocks/HalfPageLayout";
import EventLayout from "./blocks/EventLayout";
import AudioWidget from "./blocks/AudioWidget";
import TilesList from "./blocks/TilesList";
import HomePage from "./blocks/HomePage";

const main = () => {
	// const homeSlideImageRatio = () => {
	// 	document.querySelectorAll("img.slide-img").forEach(image => {
	// 		const isHomeImage = image.classList.contains("home__image");
	// 		if (!isHomeImage) {
	// 			const width = image.naturalWidth;
	// 			const height = image.naturalHeight;
	// 			const ratio = width / height;

	// 			if (ratio > 1) {
	// 				const imageWidth = window.innerHeight * ratio;

	// 				if (imageWidth > window.innerWidth) {
	// 					image.style.width = `${imageWidth}px`;
	// 					image.style.marginLeft = `-${(imageWidth - window.innerWidth) / 2}px`;
	// 				} else {
	// 					image.style.width = `${window.innerWidth}px`;
	// 					image.style.marginLeft = `0`;
	// 				}
	// 			}
	// 		}
	// 	});
	// };
	// homeSlideImageRatio();
	// window.addEventListener("resize", homeSlideImageRatio);

	document.querySelectorAll(".tabs-nav button").forEach(button => {
		button.addEventListener("toggleAfter", event => {
			if (event.target.getAttribute("data-toggle-class") === "is-open-dropdown") {
				const parent = event.target.closest(".tabs-nav-wrap");
				if (isActive(event.target)) {
					parent.classList.add("active");
				} else {
					parent.classList.remove("active");
				}
			} else {
				const target = event.target.getAttribute("data-toggle-target");
				const urlParams = new URLSearchParams(window.location.search);
				urlParams.set("tab", target.replace('#', ''));
				history.replaceState(null, null, "?" + urlParams.toString());

				if (document.querySelector(`${target} .scaled-y-text`) && isActive(event.target)) {
					window.dispatchEvent(new Event("resize-scaled-text"));
				}

				if (document.querySelector(".half-page-layout_mix-blend-mode")) {
					gsap.set(".half-page-layout_mix-blend-mode .scrolling-container", { mixBlendMode: "initial" });

					setTimeout(() => {
						gsap.set(".half-page-layout_mix-blend-mode .scrolling-container", { mixBlendMode: "multiply" });
					}, 1);
				}
			}
		}, false);
	});

	// copy link
	const handleCopyLink = (event) => {
		if (event.target.classList.contains("copy-el")) {
			const thisLink = event.target;
			let text = thisLink.getAttribute("data-href");
			navigator.clipboard.writeText(text);
			thisLink.classList.add("active");

			setTimeout(() => {
				thisLink.classList.remove("active");
			}, 800);
		}
	};
	document.addEventListener("click", handleCopyLink);

	// half page layout drop link
	document.querySelectorAll(".hpl-widget-drop-link").forEach(link => {
		link.setAttribute("data-toggle-target", "#hpl-widget-drop");
		link.setAttribute("data-toggle-class", "is-active");
		link.setAttribute("data-toggle-escape", "data-toggle-escape");

		initialize();
	});

	// modal links
	document.querySelectorAll("a").forEach(el => {
		const href = el.getAttribute("href");

		if (href.startsWith("#modal-")) {
			el.setAttribute("data-toggle-class-on-target", "is-opened");
			el.setAttribute("data-toggle-target", href);
			el.setAttribute("data-toggle-escape", "data-toggle-escape");
			el.setAttribute("data-toggle-modal", "data-toggle-modal");

			initialize();
		}
	});

	// events modal
	document.querySelectorAll(".event-card").forEach(card => {
		card.addEventListener("click", function(e) {
			const eventId = this.dataset.eventId;
			const siteId = this.dataset.siteId;

			if (card.classList.contains("event-card-fetch")) {
				e.preventDefault();
				const url = this.dataset.url;
				fetch(`/events/single?eventId=${eventId}&siteId=${siteId}`)
					.then(response => response.text())
					.then(html => {
						const eventContent = document.querySelector(".event-layout__content-inner");
						eventContent.innerHTML = "";
						eventContent.innerHTML = html;
						history.pushState({path: url}, "", url);
						window.dispatchEvent(new Event("resize-scaled-text"));
					})
					.catch(error => console.error("Error loading event:", error));
			} else {
				fetch(`/events/modal?eventId=${eventId}&siteId=${siteId}`)
					.then(response => response.text())
					.then(html => {
						const modalElement = document.querySelector("#modal-event .drop-menu");
						modalElement.innerHTML = html;
						window.dispatchEvent(new Event("resize-scaled-text"));
					})
					.catch(error => console.error("Error loading modal:", error));
			}
		});
	});

	const modalEvent = document.querySelector("#modal-event");
	if (modalEvent) {
		modalEvent.addEventListener("toggleAfter", event => {
			if (!event.target.classList.contains("is-opened")) {
				const modalElement = event.target.querySelector(".drop-menu");
				modalElement.innerHTML = "";
			}
		}, false);
	}

	// flatpickr input placeholder
	const flatpickrInputs = document.querySelectorAll(".fui-type-date .fui-input, .fui-type-date-time .fui-input");
	flatpickrInputs.forEach(input => {
		const placeHolder = input.closest(".fui-field-container").querySelector(".fui-label").textContent;
		input.setAttribute("placeholder", placeHolder);
	});

	// wrap siblings <figure>
	const wrapFigures = () => {
		const block = document.querySelector("figure");
		if (!block) {
			return;
		}

		const parent = block.parentElement;
		const els = Array.from(parent.children);
		let currentWrapper = null;
		els.forEach(el => {
			el.remove();
			if (el.tagName.toLowerCase() === "figure") {
				if (!currentWrapper) {
					currentWrapper = document.createElement('div');
					currentWrapper.classList.add("figures-group")
					parent.append(currentWrapper);
				}
				currentWrapper.append(el);
			} else {
				currentWrapper = null;
				parent.append(el);
			}
		});
	}
	wrapFigures();
};

const navTabsDropdown = () => {
	const isMobile = window.innerWidth < 1024;
	document.querySelectorAll(".tabs-nav-dropdown").forEach(el => {
		if (el) {
			const barTabsDropdown = Scrollbar.init(
				el.querySelector("ul", {
					alwaysShowTracks: false,
					damping: isMobile ? 0.2 : 0.1
				})
			);
		}
	});
};

const navTabsHorizontalScroll = () => {
	const block = document.querySelector(".tabs-nav");
	if (!block) {
		return;
	}

	if (!ScrollTrigger.isTouch) {
		class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
			static pluginName = "horizontalScroll";

			transformDelta(delta, fromEvent) {
				if (!/wheel/.test(fromEvent.type)) {
					return delta;
				}
				const { x, y } = delta;
				return {
					y: 0,
					x: Math.abs(x) > Math.abs(y) ? x : y
				};
			}
		};
		Scrollbar.use(HorizontalScrollPlugin, OverscrollPlugin);

		const bar = Scrollbar.init(
			document.querySelector(".tabs-nav", {
				alwaysShowTracks: false,
				damping: 0.1
			})
		);

		// dragable nav
		let maxWidth = 0;
		let scrollPosition = 0;
		const content = document.querySelector(".tabs-nav > .scroll-content");
		block.querySelectorAll(".scroll-content > li").forEach((el) => {
			maxWidth += el.offsetWidth;
		});

		// drag
		const drag = Draggable.create(content, {
			bounds: {
				minX: -(maxWidth - (window.innerWidth / 2)),
				maxX: 0
			},
			type: "x",
			inertia: true,
			allowContextMenu: true,
			onDragEnd() {
				scrollPosition = Math.round(this.x);
				bar.setPosition(-scrollPosition, 0);

				gsap.set(content, { x: scrollPosition < 0 ? scrollPosition : -scrollPosition });
			}
		})[0];

		// upd on scroll
		bar.addListener((s) => {
			scrollPosition = s.offset.x;
			gsap.set(content, { x: -s.offset.x });
		});
	}
};

const mainMenu = () => {
	const block = document.querySelector(".header");
	if (!block) {
		return;
	}
	const menuToggle = block.querySelector(".header__menu-toggle");
	const menuLink = block.querySelectorAll(".header__menu-link");
	const lang = block.querySelector(".header__lang");
	const drop = block.querySelector(".header__drop");
	const dropStartH = drop.offsetHeight;
	let menuIsOpen = false;

	const setLangWidth = () => {
		lang.removeAttribute("style");
		lang.style.minWidth = `${document.querySelector(".header__menu-item:last-child").offsetWidth}px`;
	};
	setLangWidth();

	const showMenu = () => {
		gsap.fromTo(drop, { height: dropStartH }, { height: "auto", duration: 0.2, ease: "power2.out" });
			setTimeout(function () {
				drop.removeAttribute("style");
			}, 250);
			menuIsOpen = true;
			block.classList.add("header_active");
	};

	const hideMenu = () => {
		gsap.fromTo(drop, { height: drop.offsetHeight }, { height: dropStartH, duration: 0.2, ease: "power1.in" });
		setTimeout(function () {
			block.classList.remove("header_active");
			menuIsOpen = false;
		}, 200);
	};

	const toggleMenuAnimation = () => {
		if (menuIsOpen) {
			hideMenu();
		} else {
			showMenu();
		}
	};

	menuToggle.addEventListener("click", (e) => {
		toggleMenuAnimation();
	});

	menuLink.forEach(el => {
		el.addEventListener("click", (e) => {
			toggleMenuAnimation();
		});
	});

	const handleClosureMenu = (event) => {
		if (!block.contains(event.target)) {
			hideMenu();
		}
	};

	document.addEventListener("click", handleClosureMenu);
	window.addEventListener("resize", setLangWidth);
};

const mobileMultilineHeading = () => {
	if (window.innerWidth < 768) {
		document.querySelectorAll(".mobile-multiline-title").forEach((item) => {
			if (!item.querySelector(".mobile-hidden")) {
				const text = item.querySelector("text").innerHTML;
				const svg = item.querySelector("svg");
				svg.classList.add("mobile-hidden");

				const mobileTitle = document.createElement("div");
				mobileTitle.classList.add("mobile-visible");
				mobileTitle.innerHTML = text;
				item.append(mobileTitle);

				const scaledTitle = item.querySelector(".mobile-visible");
				scaledTitle.removeAttribute("style");
				const elHeight = scaledTitle.offsetHeight;
				gsap.set(scaledTitle, { height: elHeight * 2, scaleY: 2 });
			}
		});
	}
};

const handleActiveTab = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const tabParam = urlParams.get("tab");

	if (tabParam) {
		const currentTab = document.querySelector(`[data-toggle-target="#${tabParam}"]`);
		if (currentTab) {
			setTimeout(() => {
				currentTab.dispatchEvent(new Event("click", {"bubbles": true}));
			}, 50);
		}
	}
};

const pageTitleScrollAnimation = () => {
	const block = document.querySelectorAll(".page-title");
	if (!block) {
		return;
	}

	const resetTitleScrollTrigger = () => {
		ScrollTrigger.getAll().forEach((st) => {
			if (st.trigger.className.includes("page-title")) {
				st.kill();
			}
		});
	};

	const initTitleAnimation = (title) => {
		const TitleTL = gsap.timeline({
			scrollTrigger: {
				trigger: title,
				scroller: ".scrolling-container",
				start: "top top",
				end: `+=${title.offsetHeight * 0.93}`,
				scrub: true
			}
		})
		TitleTL.fromTo(title, { scaleY: 1 }, { scaleY: 0, duration: 1, ease: "none" });
	};

	block.forEach(el => {
		const text = el.textContent.trim();
		if (text.length < 8) {
			el.classList.add("page-title_offsets-xs");
		}
		el.addEventListener("fit-svg", (e) => {
			resetTitleScrollTrigger();
			initTitleAnimation(el);
		});
	});
};

// const mainSlides = () => {
// 	const slides = document.querySelectorAll(".slide");

// 	slides.forEach(slide => {
// 		const wrapper = slide.querySelector(".slide-wrapper");

// 		if (wrapper) {
// 			const TLShow = gsap.timeline({
// 				scrollTrigger: {
// 					trigger: wrapper,
// 					scroller: ".scrolling-container",
// 					start: "top +=22vh",
// 					end: `+=${window.innerHeight * 2}px`,
// 					pin: true,
// 					pinSpacer: false,
// 					scrub: true
// 				}
// 			})
// 			TLShow.fromTo(wrapper,
// 				{ scaleY: 0 },
// 				{ scaleY: 1, duration: 1, ease: "none" }
// 			);
// 			TLShow.set(wrapper, { transformOrigin: "top center" });
// 			TLShow.to(wrapper, { scaleY: 0, duration: 1, ease: "none" });
// 		}
// 	});
// };

const scaledText = () => {
	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			document.querySelectorAll(".scaled-y-text").forEach(el => {
				el.removeAttribute("style");
				const elHeight = el.offsetHeight;
				gsap.set(el, { height: elHeight * 2, scaleY: 2 });
			});
		});
	});
};

docReady(() => {
	vhCheck();
	easyToggle();
	gsap.registerPlugin(ScrollTrigger, Draggable);

	// ScrollAnimation();
	HomePage();
	main();
	scaledText();
	mainMenu();
	ExhibitionsBox();
	mobileMultilineHeading();
	handleActiveTab();
	pageTitleScrollAnimation();
	ContactBox();
	InfoTiles();
	InfoList();
	HalfPageLayout();
	EventLayout();
	navTabsDropdown();
	navTabsHorizontalScroll();
	// mainSlides();
	AudioWidget();
	TilesList();

	window.addEventListener("resize", () => {
		scaledText();
		mobileMultilineHeading();
	});
	window.addEventListener("resize-scaled-text", () => {
		scaledText();
	});
	document.fonts.ready.then(function () {
		scaledText();
	});
});

/*cookie warning */
let cookieBanner;
function checkCookieWarning() {
	cookieBanner = document.querySelector('#cookie-warning');
	// console.log(document.cookie)
	//Check for our cookie
	if (document.cookie.includes("cookieWarningBanner")) {
		//Hide the popup
		hideCookieWarning();
	} else {
		//Show the popup, ie do nothing
		//console.log("show it");
		cookieBanner.classList.remove("hide");
	}
	let cookieOk = document.querySelector('.cookie-ok');
	cookieOk.addEventListener("click", function() {
		hideAndSetCookieWarning()
	});
}
function hideCookieWarning() {
	// console.log('hiding')
	cookieBanner.classList.add("hide");
}
function hideAndSetCookieWarning() {
	//runs if you click ok.
	cookieBanner.classList.add("hide");
	let d = new Date();
	//Increment the current time by 1 minute (cookie will expire after 1 minute)
	d.setMonth(+1 + d.getMonth());
	//d.setMinutes(d.getMinutes() + 1)
	//Create Cookie withname = myCookieName, value = thisIsMyCookie and expiry time=1 minute
	document.cookie = "cookieWarningBanner=noBanner; expires = " + d.toUTCString() + ";";
}
checkCookieWarning();