const InfoList = () => {
	const block = document.querySelectorAll(".info-list");
	if (!block) {
		return;
	}

	block.forEach((item, index) => {
		const image = item.querySelector(".info-list__image");
		const row = item.querySelector(".info-list__row");
		const cols = row.querySelectorAll(".info-list__col");

		item.classList.add(`info-list_col-${cols.length}`);

		if (image) {
			item.classList.add("info-list_with-image");
		}
	});
};


export default InfoList;