import Swiper from "swiper/bundle";
import { gsap } from "gsap";

const InfoTiles = () => {
	const block = document.querySelectorAll(".info-tiles");
	if (!block) {
		return;
	}
	const breakpoint = window.matchMedia("(min-width: 768px)");

	block.forEach((item, index) => {
		const sliderWrap = item.closest(".info-tiles-slider-wrapper");
		const sliderItems = item.querySelectorAll(".info-tiles__item");
		let mySwiper;

		if (sliderWrap) {
			const handleInitSLider = () => {
				item.classList.add("swiper-wrapper");
	
				sliderItems.forEach((slide) => {
					slide.classList.add("swiper-slide");
				});
	
				mySwiper = new Swiper(sliderWrap, {
					slidesPerView: 1.19,
					initialSlide: 0
				});
			};

			const breakpointChecker = function() {
				if (breakpoint.matches === true) {
					if (mySwiper !== undefined) {
						mySwiper.destroy(true, true);
						sliderItems.forEach((slide) => {
							slide.classList.remove("swiper-slide");
						});
					}
					return;
				} else if (breakpoint.matches === false) {
					handleInitSLider();
				}
			};
	
			breakpoint.addListener(breakpointChecker);
			breakpointChecker();
		}
	});

	// set title same height in row
	const setHeight = (el, val) => {
		if (typeof val === "function") val = val();
		if (typeof val === "string") el.style.height = val;
		else el.style.height = val + "px";
	}
	const equalheight = (container) => {
		var currentTallest = 0;
		var currentRowStart = 0;
		var rowDivs = new Array();
		let topPosition = 0;
		let currentDiv;
	
		Array.from(document.querySelectorAll(container)).forEach(el => {
			el.style.height = "auto";
			topPosition = el.offsetTop;
			if (currentRowStart != topPosition) {
				for (currentDiv = 0; currentDiv < rowDivs.length ; currentDiv++) {
					setHeight(rowDivs[currentDiv], currentTallest)
				}
				rowDivs.length = 0;
				currentRowStart = topPosition;
				currentTallest = parseFloat(getComputedStyle(el, null).height.replace("px", ""))
				rowDivs.push(el);
			} else {
				rowDivs.push(el);
				currentTallest = (currentTallest < parseFloat(getComputedStyle(el, null).height.replace("px", ""))) ? (parseFloat(getComputedStyle(el, null).height.replace("px", ""))) : (currentTallest);
			}
			for (currentDiv = 0 ; currentDiv < rowDivs.length; currentDiv++) {
				setHeight(rowDivs[currentDiv], currentTallest)
			}
		})
	};

	equalheight(".info-tiles__card-title");
	equalheight(".info-tiles__wrap");

	window.addEventListener("resize", function(){
		equalheight(".info-tiles__card-title");
		equalheight(".info-tiles__wrap");
	});
};


export default InfoTiles;